import DrynessLocationTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-dryness/dryness-location/DrynessLocationTemplate';

import { FACIAL_DRYNESS, GENDER } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinDryness/DrynessLocation/DrynessLocationTemplate',
  component: DrynessLocationTemplate
};

const createStory = props => () => ({
  components: { DrynessLocationTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><dryness-location-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  gender: GENDER.MALE,
  facialDrynessLocations: FACIAL_DRYNESS.CHEEKS_AND_TZONE
});
